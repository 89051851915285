.overlay-img {
    .shape-01 {
        position: absolute;
        left: 40%;
    }
    .shape-02 {
        position: absolute;
        left: 60%;
        bottom: 0;
    }
    .shape-03 {
        position: absolute;
        left: 80%;
    }
    .shape-04 {
        position: absolute;
        right: 15%;
        bottom: 0;
    }
}
.event-scroll {
    max-height: 495px;
    overflow-y: auto;
}
.class-hover {
    @include transition(all 0.5s ease);
    &:hover {
        @include transition(all 0.5s ease);
        .avatar {
            animation: rotate  1s ease-in-out;
        }
    }
}
@keyframes rotate {
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(90deg);
    }
    100% {
      transform: rotateY(0deg);
    }
  }
.btn-hover {
    @include transition(all 0.5s ease);
    &:hover {
        background-color: $dark !important;
        color: $white !important;
    }
}
.todo-list {
    .todo-strike-content {
        h6 {
            text-decoration-line: line-through;
        }
    }
}
.dark-hover {
    &:hover {
        background: $primary !important;
        border-color: $primary !important;
        color: $white;
    }
}
.line-ellipsis {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; 
}
.student-slider, .teacher-slider {
    &.owl-carousel .owl-stage-outer {
        height: 100% !important;
        .owl-stage {
            height: 100%;
            .owl-item {
                height: 100%;
            }
        }
    }
}
.activity-scroll {
    max-height: 240px;
    overflow-y: auto;
}
.notice-widget {
    position: relative;
    &:before {
        position: absolute;
        content: "";
        width: 1px;
        height: calc(100% - 25px);
        background-color: $light-900;
        top: 20px;
        inset-inline-start: 16px;
    }
}
.student-slider.owl-carousel  {
    .owl-nav {
        position: absolute;
        left: 50%;
        top: 30%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        button {
            width: 24px;
            height: 24px;
            background: $white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: $font-size-12;
            &.owl-prev {
                margin-right: 5px;
            }
            &:hover {
                background: $primary;
                color: $white;
            }
        }
    }
}
.teacher-slider.owl-carousel  {
    .owl-nav {
        position: absolute;
        left: 50%;
        top: 30%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        button {
            width: 24px;
            height: 24px;
            background: $white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: $font-size-12;
            color: $dark;
            &.owl-prev {
                margin-right: 5px;
            }
            &:hover {
                background: $primary;
                color: $white;
            }
        }
    }
}
.animate-card {
    @include transition(all 0.5s ease);
    &:hover {
        @include transition(all 0.5s ease);
        @include transform(translateY(-3px));
    }
}
