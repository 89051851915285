.editable-field-wrapper {
  position: relative;
  min-height: 24px;
  padding: 2px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 1;
}

.editable-field-wrapper .text-truncate {
  max-width: calc(100% - 24px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.editable-field-content {
  padding: 12px;
  min-width: 250px;
  max-width: 300px;
}

.editable-field-popover {
  position: relative;
}

.editable-field-popover.ant-popover {
  position: absolute;
  z-index: 9999999;
}

.editable-field-popover.ant-popover .ant-popover-inner {
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 9999999;
}

.editable-field-popover.ant-popover .ant-popover-inner-content {
  padding: 0;
  z-index: 9999999;
}

.editable-field-popover .ant-input,
.editable-field-popover .ant-picker {
  width: 100%;
}

.editable-field-popover .form-control {
  font-size: 14px;
}

.editable-field-popover .form-control.is-invalid {
  border-color: #ff4d4f;
}

.editable-field-popover .invalid-feedback {
  font-size: 12px;
  color: #ff4d4f;
  margin-top: 4px;
}

.editable-field-popover .btn {
  font-size: 12px;
  padding: 4px 12px;
}

.editable-field-popover .btn-light {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.85);
}

.editable-field-popover .btn-primary {
  background-color: #1890ff;
  border-color: #1890ff;
  color: white;
}

.editable-field-popover .btn-primary:disabled {
  background-color: #d9d9d9;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.editable-field-popover .form-label {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

.editable-field-popover .PhoneInput {
  display: flex;
  align-items: center;
}

.editable-field-popover .PhoneInputInput {
  flex: 1;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
}

.editable-field-popover .PhoneInputCountry {
  margin-right: 8px;
}

.editable-field-popover .ant-picker {
  border-radius: 4px;
}

.editable-field-popover .ant-picker-input > input {
  font-size: 14px;
}

/* Fix for address fields */
.editable-field-wrapper[data-field-type="textarea"] {
  display: block;
  position: relative;
}

.editable-field-wrapper[data-field-type="textarea"] .text-truncate {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  padding-right: 24px;
}

.editable-field-wrapper[data-field-type="textarea"] .btn-link {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

/* Fix for tooltip positioning */
.editable-field-popover.ant-popover {
  position: absolute;
  z-index: 9999999;
}

.editable-field-popover.ant-popover .ant-popover-arrow {
  display: none;
}

.editable-field-popover.ant-popover .ant-popover-inner {
  margin: 0;
  z-index: 9999999;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .editable-field-popover.ant-popover {
    position: fixed;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 90% !important;
    max-width: 300px !important;
    z-index: 9999999;
  }

  .editable-field-content {
    width: 100%;
    max-width: 100%;
  }
}
