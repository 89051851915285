
.daterangepicker {
    .ranges {
        li.active {
            background-color: $primary;
        }
    }
    td {
        &.active {
            background-color: $primary;
            &:hover {
                background-color: $primary;
            }
        }
    }
}
.daterange-wraper {
    .date-range {
        padding-left: 30px;
        font-size: $font-size-14;
        color: $gray-700;
        border: 0;
        min-height: auto;
        height: 36px;
        border: 0;
        box-shadow: 0px 4.4px 20px -1px #1310220D;
        &:focus{
            border-left:0;
        }
    }
    .cal-icon-date {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
}