.chat-user-actives {
    .call-meet-blk {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        background: $white;
        border-radius: 5px;
        margin-bottom: 15px;
        padding: 10px;
        @include transition(all 0.5s ease);
        &:hover {
            background-color: $black;
            @include transition(all 0.5s ease);
        }
        .meet-border {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            width: 39px;
            height: 39px;
            background: $white;
            border: 0.5px solid $gray-300;
            border-radius: 10px;
            margin-right: 10px;
            svg {
                width: 16px;
                color: $blue;
            }
        }
        .recent-user-titles {
            h5 {
                font-size: $font-size-base;
                font-weight: $font-weight-semibold;
                color: $black;
                margin-bottom: 5px;
                width: 150px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            p {
                font-size: $font-size-10;
                color: $text-color;
            }
        }
    }
}
.new-join-link {
    .chat-user-actives {
        .meet-border {
            background: gray;
            border: 0;
            border-radius: 6px;
            &:hover {
                background: $primary;
                svg {
                    color: $white;
                }
            }
            svg {
                color: $primary;
            }
        }
        .recent-user-titles {
            h5 {
                color: $primary;
                width: 100%;
                margin-bottom: 0;
                font-size: $font-size-15;
            }
        }
    }
}
.meet-chat-blk {
    .join-call {
        .join-video {
            position: relative;
            margin-bottom: 25px;
            img {
                border-radius: 10px;
            }
            .video-avatar {
                color: $white;
                background: #5D5D5D;
                font-size: 50px;
                border-radius: 10px;
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                display: none;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                justify-content: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                &.active {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    position: relative;
                    height: 426px;
                }
            }
            .call-icons {
                text-align: center;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                .call-items {
                    border-radius: 5px;
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    display: -webkit-inline-box;
                    display: -ms-inline-flexbox;
                    display: inline-flex;
                    position: absolute;
                    bottom: 30px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    .call-item {
                        display: inline-block;
                        text-align: center;
                        margin-right: 15px;
                        &:last-child {
                            margin-right: 0px;
                        }
                        a {
                            color: $white;
                            background: $white;
                            border-radius: 10px;
                            width: 54px;
                            height: 54px;
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            align-items: center;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            justify-content: center;
                            -webkit-justify-content: center;
                            -ms-flex-pack: center;
                            font-size: $font-size-20;
                            white-space: nowrap;
                            @include respond-below(md) {
                                width: 45px;
                                height: 45px;
                            }
                            i {
                                color: $text-color;
                            }
                            &.stop {
                                color: $white;
                                background: $danger;
                                i {
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }
            .meet-drop {
                ul {
                    li {
                        i {
                            font-size: $font-size-18;
                        }
                    }
                }
            }
            
        }
        .join-content {
            text-align: center;
            max-width: 500px;
            margin: auto;
            h4 {
                font-size: $font-size-22;
                font-weight: $font-weight-semibold;
                margin-bottom: 20px;
                @include respond-below(md) {
                    font-size: $font-size-18;
                }
            }
            p {
                font-size: $font-size-13;
                color: $text-color;
            }
        }
    }
}
.conference-meet-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    .meeting-list {
        width: 100%;
        transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        &.add-meeting {
            width: 65%;
            margin-right: 24px;
            @include respond-below(md) {
                display: none;
                margin-right: 0px;
            }
        }
    }
    .right-user-side {
        width: 0;
        opacity: 0;
        transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        &.right-side-party {
            width: 0;
            opacity: 0;
            transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            -ms-transition: all 0.5s ease;
            -webkit-transition: all 0.5s ease;
            .party-msg-blk  {
                .last-call-time {
                    .call-recent {
                        background: none;
                        svg {
                            color: $primary;
                            width: 14px;
                        }
                    }
                }
            }
        }
        &.chat-rooms {
            width: 0;
            opacity: 0;
            transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            -ms-transition: all 0.5s ease;
            -webkit-transition: all 0.5s ease;
            .slime-grp {
                padding: 0;
                .left-chat-title {
                    padding: 20px;
                    border-bottom: 1px solid $light-900;
                    border-radius: 10px 10px 0 0;
                }
            }
            &.open-chats {
                width: 35%;
                opacity: 1;
                @include respond-below(md) {
                    width: 100%;
                }
            }
        }
        &.open-message {
            width: 35%;
            opacity: 1;
            @include respond-below(md) {
                width: 100%;
            }
        }
    }
}
.meet-drop {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    position: absolute;
    top: 20px;
    right: 20px;
    &.meet-mutes {
        background: $primary;
        right: inherit;
        left: 20px;
        ul {
            li {
                a {
                    color: $white;
                    width: 50px;
                    height: 50px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    justify-content: center;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;
                    @include respond-below(md) {
                        width: 38px;
                        height: 38px;
                    }
                }
            }
        }
    }
    &.meet-mutes-bottom {
        background: $primary;
        bottom: 20px;
        border-radius: 50px;
        top: inherit;
        ul {
            li {
                a {
                    color: $white;
                    width: 50px;
                    height: 50px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    justify-content: center;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;
                    @include respond-below(md) {
                        width: 38px;
                        height: 38px;
                    }
                    i {
                        font-size: $font-size-20;
                    }
                }
            }
        }
    }
}
.meet-call-menu-blk {
    .video-call-action {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        @include respond-below(md) {
            display: block;
        }
        ul {
			display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            justify-content: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            li {
                margin-right: 5px;
                &:last-child {
                    margin-right: 0;
                }
                a {
                    width: 40px;
                    height: 40px;
                    border-radius: 50px;
                    background: $gray-200;
                    border: 1px solid $gray-200;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    justify-content: center;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;
                    color: $black;
					font-size: $font-size-18;
                    &.call-end {
                        background-color: $danger;
                        border-color: $danger;
                        color: $white;
						width: 60px;
                    	height: 60px;
						rotate: 135deg;
						font-size: $font-size-20;
                    }
                    &.stop {
                        color: $white;
                        background: $danger;
                        border-color: $danger;
                        i {
                            color: $white;
                        }
                    }
                }
                &.active {
                    a {
                        background: $primary;
                        border-color: $primary;
                        color: $white;
                        img {
                            filter: invert(1) brightness(100);
                        }
                    }
                }
            }
            @include respond-below(md) {
                margin-bottom: 5px;
                justify-content: center;
            }
            &:last-child {
                @include respond-below(md) {
                    margin-bottom: 0px;
                }
            }
        }
    }
}
.recording-time {
    position: relative;
    padding-left: 20px;
    &::before {
        content: "";
        position: absolute;
        left: 0px;
        top: 4px;
        background-color: $danger;
        width: 13px;
        height: 13px;
        border-radius: 50px;
    }
    &::after {
        content: "";
        position: absolute;
        left: 3px;
        top: 7px;
        background-color: $white;
        width: 7px;
        height: 7px;
        border-radius: 50px;
        animation: blink-animation 1.5s steps(5, start) infinite;
        -webkit-animation: blink-animation 1.5s steps(5, start) infinite;
    }
}

.page-content {
	width: 100%;
}
.join-call {
    width: 100%;
    margin: 0 auto;
}
.join-video {
	position: relative;
	margin-bottom: 25px;
    img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &.join-video-front {
        img {
            height: 423px;
        }      
    }
}
.video-avatar {
    color: $white;
    background: $gray-300;
    font-size: 50px;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    h4 {
        font-weight: $font-weight-bold;
        font-size: $font-size-24;
        color: $white;
    }
}
.video-avatar.active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.join-video.video-hide > img, 
.video-wrap.video-hide > video {
    display: none;
}
.join-content {
    p {
        font-weight: $font-weight-normal;
        font-size: $font-size-14;
        margin-bottom: 25px;
        color: $black;
    }
    label {
        font-size: $font-size-18;
        margin-bottom: 0;
        color: $black;
        font-weight: $font-weight-bold;
    }
}
.part-name {
	position: absolute;
	top: 20px;
	left: 12px;
	text-align: left;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 50px;
    padding: 5px 15px;
    h4 {
        font-weight: $font-weight-medium;
        font-size: $font-size-15;
        color: $text-color; 
        margin-bottom: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @include respond-below(md) {
            font-size: $font-size-13;
        }
    }
    &.sub-part-name {
        background: $white;
        bottom: 10px;
        top: inherit;
        h4 {
            color: $gray-900;
            font-size: $font-size-12;
        }
    }
}
.grid-join {
    .part-name {
        background: rgba(0, 0, 0, 0.3);
        top: inherit;
        bottom: 20px;
        h4 {
            color: $white;
        }
    }
}
.join-hover-img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    .event-user-group {
        .avatar-group {
            background: rgba(0, 0, 0, 0.3);
            padding: 2px 10px;
            border-radius: 50px;
            .avatar {
                width: 26px;
                height: 26px;
                border: 1px solid $white;
                &+.avatar {
                    margin-left: -16px;
                }
                &:last-child {
                    margin-right: 0px;
                }
            }
        }
    }
}
.more-icon {
	position: absolute;
	bottom: 10px;
	right: 12px;
    @include respond-below(md) {
        bottom: 8px;
        right: 13px;
    }
    a {
        color: $text-color;
        width: 30px;
        height: 30px;
        background: $white;
        border-radius: 50px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        &:hover {
            opacity: 0.8;
        }
        &.other-mic-off {
            &.stop {
                i {
                    color: $danger;
                }
            }
        }
    }
    &.audio-more-icon {
        a {
            &.other-mic-off {
                &.stop {
                    background-color: $danger !important;
                    i {
                        color: $white;
                    }
                }
            }
        }
    }
}
.call-recent {
    &.recent-part {
        a {
            &.other-mic-off, &.other-video-off {
                &.stop {
                    background: $danger !important;
                    i {
                        color: $white;
                    }
                }
            }
        }
    }
}
.meeting {
    .part-name {
        top: 15px;
        left: 15px;
    }
    .more-icon {
        top: 15px;
        right: 15px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}
.vertical-view {
    .vertical-sec {
        .part-name {
            h4 {
                font-size: $font-size-14;
	            font-weight: $font-weight-normal;
            }
        }
    }
}
.horizontal-view {
    .join-video {
        &.single-user {
            .part-name h4 {
                font-size: $font-size-14;
                font-weight: $font-weight-normal;
            }
        }
    }
}
.meeting-wrapper, .meeting {
	width: inherit;
	max-width: inherit;
}
.meet-footer {
	position: fixed;
	bottom: 25px;    
	width: 100%;
	max-width: 950px;
	left: 50%;
    transform: translate(-50%, 0);
	z-index: 3;
}
.show-chat-list, .show-invite-list,
.show-chat-list .user-sidebar-right,
.show-invite-list .invite-sidebar-right {
    transition: all 0.8s ease;
}
.user-sidebar-right, .invite-sidebar-right {
    background: #dedede;
	border-radius: 10px;
    width: 410px;
    position: absolute;
    top: 105px;
    bottom: 117px;
    right: 0;
    margin-right: -410px;
    transition: all 0.8s ease;
    overflow: hidden;
    font-size: 13px;
	z-index: 3;
}
.join-contents {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	box-sizing: border-box;
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
    &.grid-view {
        align-content: center;
    }
}
.ripple {
	width: 114px;
    height: 114px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
	display: -webkit-box;
	display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.multigrid-view {
    .text-box {
        height: 61px;
        width: 61px;
    }
}
.multigrid-view {
    .ripple {
        width: 80px;
        height: 80px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}
.meeting {
    .join-video {
        margin: 0 5px 10px;	
        width: 100%;
    }
}
.join-video {
    &.user-active {
        background: #415B6A;
        border-radius: 10px;
        width: 100%;
        height: 370px;
        &.audio-calls {
            height: calc(100vh - 250px);
            padding: 60px 0;
        }
    }
    &.vertical-active {
        margin-right: 24px;
    }
    &.single-user {
        width: 100%;	
        min-height: 230px;
        box-sizing: border-box;
        margin-right: 24px;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        text-align: center;
        height: auto;
        background: #415B6A;
        border-radius: 10px;
        padding: 0;
        flex-grow: 1;
        @include respond-below(xxl) {
            width: 100%;
        }
        @include respond-below(xl) {
            min-height: 203px;
        }
        @include respond-below(lg) {
            min-height: 230px;
        }
        &:nth-child(even) {
            margin-right: 0px;
            @include respond-below(md) {
                margin-right: 10px;
            }
        }
        @include respond-below(md) {
            width: 100%;
            min-height: 257px;
        }
    }

}
.multi-grid {
    .join-video.single-user {
        @include respond-below(md) {
           width: 45%;
        }
        &:nth-child(even) {
            margin-right: 24px;
            @include respond-below(md) {
                margin-right: 0px;
            }
        }
        &:nth-child(4n) {
            margin-right: 0px;
            @include respond-below(md) {
                &:nth-child(4n) {
                    margin-right: 0px;
                }
            }
        }
    }
}
.multigrid-view {
    .join-video {
        &.single-user {
            flex-grow: 0;
        }
    }
}
.horizontal-view {
    .join-video.single-user {
        &:nth-child(even) {
            margin-right: 24px;
        }
        &:nth-child(5) {
            margin-right: 0px;
        }
        &:nth-child(3n) {
            @include respond-below(md) {
                margin-right: 0
            }
        }
    }
}
.text-avatar {
	color: $white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h4 {
        color: $white;
    }
}
.text-box {
    height: 86px;
    width: 86px;	
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    font-size: $font-size-36;
	font-weight: $font-weight-bold;
	display: -webkit-box;
	display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.more-icon.mic-view .mic-on, .more-icon .mic-off {
	display: none;
}
.more-icon.mic-view .mic-off {
    display: inline-flex;
}
.grid-view .join-video.single-user {
    width: 47%;	
    @include respond-below(md) {
        width: 40%;	
    }
    &:nth-child(2n) {
        @include respond-below(md) {
            margin-right: 0px;
        }
    }
    &:nth-child(3n) {
        @include respond-below(md) {
            margin-right: 24px;
        }
    }
}

.join-contents.multigrid-view {
	max-width: 100%;
	justify-content: center;
}
.meeting {	
	position: absolute;
	top: 80px;
	bottom: 107px;
	left: 0;
	right: 0;
}
.meeting-list {
	flex-direction: column;
	height: 100%;
	width: 100%;
	position: relative;
}
.user-active {
    .more-icon {
        .handraise-on {
            display: none;
            &.active {
                display: block;
                animation: mymove 5s;
            }
        }
    }
}
.user-active .more-icon .handraise-on.active .meeting .join-video.user-active::before {
	animation: mymove 5s infinite;
	opacity: 1;
}
@keyframes mymove {
	from {color: #0074E0;}
	to {color: transparent;}
}
.join-contents {
    &.vertical-view {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        align-content: center;
        max-width: 1300px;
        @include respond-below(md) {
            display: block;
        }
        .join-video {
            &.user-active {
                flex: 1;
                margin-bottom: 24px;;
            }
            &.single-user {
                width: 97%;
                flex-grow: 1;
                display: flex;
                @include respond-below(md) {
                    width: 100%;
                }
            }
        }
        .vertical-sec {
            width: 20%;
            @include respond-below(lg) {
                width: 30%;
            }
            @include respond-below(md) {
                width: 100%;
            }
        }
    }
    &.vertical {
        flex-direction: column;
        height: 200px;
    }
}
.meeting .join-contents.vertical .join-video.user-active {
	flex: 1 0 100%;
}
.meeting .join-contents.vertical .join-video.single-user {
	flex: 0;
}
.meeting .join-contents.vertical .join-video {
	width: auto;
}
.chat-footer .btn:hover {
	background: $primary-hover;
	color: $white;
}
.join-contents {
    &.show-whiteboard {
        display: none;
    }
    &.single-view {
        padding-top: 0;
        margin-top: 25px;
        position: relative;
        .single-user {
            position: absolute;
            right: 8px;
            top: 40px;
            width: 130px;
            height: 130px ;
            min-height: 130px ;
            img {
                height: 130px ;	
            }
        }
    }
}
.record-time {
    position: absolute;
    top: 20px;
    z-index: 1;
    position: absolute;
    left: 20px;
	span {
		background-color: rgba(255, 255, 255, 0.2);
		display: inline-block;
		border-radius: 130px;
		color: $white;
		font-size: $font-size-14;
		font-weight: $font-weight-semibold;
		width: 80px;
		height: 30px;
		text-align: center;
		line-height: 34px;
		position: relative;
		padding-left: 15px;
		&:before {
			content: "";
			position: absolute;
			left: 7px;
			top: 7px;
			background-color: $white;
			width: 18px;
			height: 18px;
			border-radius: 50px;
		}
		&:after {
			content: "";
			position: absolute;
			left: 12px;
			top: 12px;
			background-color: $danger;
			width: 8px;
			height: 8px;
			border-radius: 50px;
			animation: blink-animation 1.5s steps(5, start) infinite;
			-webkit-animation: blink-animation 1.5s steps(5, start) infinite;
		}
	}
}

@keyframes blink-animation {
	to {
		visibility: hidden;
	}
}

@-webkit-keyframes blink-animation {
	to {
		visibility: hidden;
	}
}
.volume-col {
	position: absolute;
	left: 20px;
	bottom: 20px;
}
.volume-col .inner-volume-col {
	background-color: $gray-300;
    border-radius: 130px;
    width: 30px;
    height: 120px;
    position: relative;
    z-index: 9;
}
.volume-col #volume {
	position: absolute;
    left: 50%;
    top: 15px;
    margin: 0 auto;
    height: 80px;
    width: 5px;
    background: $white;
    border-radius: 15px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
}
.volume-col #volume .ui-slider-range-min {
	height: 5px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: $primary;
    border: none;
    border-radius: 10px;
    outline: none;
}
.volume-col #volume .ui-slider-handle {
	width: 20px;
    height: 20px;
    border-radius: 20px;
    background: $primary;
    position: absolute;
    left: 50%;
    margin-top: -8px;
    cursor: pointer;
    outline: none;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
}
.volume-col #player {
	width: auto;
    height: 104px;
    position: relative;
    margin: 0 auto;
    top: 0px;
}

.volume-col #player i {
	position: absolute;
	margin-top: -6px;
	color: #666;
}

.volume-col .volume-icons {
	color: $white;
	margin-left: 0px;
	font-size: $font-size-18;
	margin-top: 22px;
}
.chat-msg-blk {
    padding: 15px;
    .chats {
        max-width: 100%;
        margin-bottom: 1.5rem;
        display: flex;
        display: -webkit-flex;
        @include respond-below(md) {
            max-width: 100%;
        }
        .chat-action-btns {
            .chat-user-time {
                .chats-dot {
                    a {
                        color: $green;
                    }
                }
            }
        }
        &.chats-right {
            margin-left: auto;
            flex-direction: row-reverse;
            -webkit-flex-direction: row-reverse;
            .chat-content {
                order: 1;
                -webkit-order: 1;
                .message-content {
                    background-color: $light-900;
                    border-radius: 8px;
                    .chat-time {
                        justify-content: flex-start;
                        -webkit-justify-content: flex-start;
                    }
                    .download-col {
                        position: relative;
                         ul {
                            li {
                                margin-right: 8px;
                                &:last-child {
                                    margin-right: 0px;
                                }
                                .image-download-col {
                                    position: relative;
                                    &.image-not-download {
                                        a {
                                            img {
                                                -webkit-filter: blur(4px);
                                                filter: blur(1px);
                                                border-color: $black;
                                            }
                                        }
                                        span {
                                            position: absolute;
                                            top: 50%;
                                            left: 50%;
                                            -webkit-transform: translate(-50%, -50%);
                                            -ms-transform: translate(-50%, -50%);
                                            transform: translate(-50%, -50%);
                                            i {
                                                color: $white;
                                            }
                                        }
                                    }
                                    .download-action {
                                        position: absolute;
                                        right: 8px;
                                        bottom: 8px;
                                        line-height: 0;
                                        a {
                                            color: $white;
                                            font-size: 10px;
                                        }
                                    }
                                    img {
                                        border-radius: 5px;
                                    }
                                }
                            }
                         }
                    }
                    &::before {
                        border-bottom: 6px solid transparent;
                        border-left: 6px solid $light-900;
                        border-right: 0;
                        border-top: 6px solid transparent;
                        content: "";
                        height: 0;
                        width: 0;
                        position: absolute;
                        top: 8px;
                        right: -4px;
                        left: auto;
                    }
                }
            }
            .chat-action-btns {
                order: 2;
                -webkit-order: 2;
                display: flex;
                display: -webkit-flex;
                flex-wrap: wrap;
                -webkit-flex-wrap: wrap;
                justify-content: center;
                -webkit-justify-content: center;
                .chat-user-time {
                    .chats-dot {
                        a {
                            color: $green;
                        }
                    }
                }
            }
        }
        .message-content {
            background-color: $light-900;
            border-radius: 8px;
            padding: 10px 20px;
            font-weight: $font-weight-medium;
            margin-bottom: 5px;
            position: relative;
            h4 {
                font-size: $font-size-13;
                color: $gray-900;
                margin-bottom: 0px;
                line-height: 1.4;
                font-weight: $font-weight-medium;
            }
            .chat-time {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                justify-content: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                font-size: $font-size-10;
                font-weight: $font-weight-medium;
                color: $green;
                margin-bottom: 0;
                svg {
                    width: 10px;
                }
            }
            &::before {
                border-bottom: 6px solid transparent;
                border-right: 6px solid $light-900;
                border-top: 6px solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                top: 8px;
                left: -4px;
                right: auto;
            }
        }
        .chat-profile-name {
            h6 {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                justify-content: left;
                -webkit-justify-content: left;
                -ms-flex-pack: left;
                font-size: 8px;
                font-weight: $font-weight-medium;
                color: $text-color;
				i {
					font-size: 12px;
					color: #008024;
				}
            }
        }
    }
    .chat-line {
        margin: 0px 0 20px;
        position: relative;
        text-align: center;
        width: 100%;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            background-color: $green;
            width: 100%;
            height: 1px;
        }
        .chat-date {
            background-color: $green;
            color: $green;
            font-size: $font-size-13;
            padding: 7px 14px;
            border-radius: 3px;
            display: inline-block;
            font-weight: $font-weight-semibold;
            position: relative;
        }
    }
}
.right-user-side {
    &.call-user-side {
        .slime-grp {
            .right-sidebar-profile {
                justify-content: space-between;
                -webkit-justify-content: space-between;
                -ms-flex-pack: space-between;
                border-bottom: 0;
                padding-bottom: 10px;
                .profile-call-blk {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    justify-content: center;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;
                }
                .edit-group {
                    svg {
                        width: 16px;
                        color: $green;
                    }
                }
            }
            .chat-options {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                justify-content: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                border-bottom: 1px solid $green;
                padding-bottom: 20px;
                ul {
                    li {
                        a {
                            color: $green;
                            width: 30px;
                            height: 30px;
                            background-color: gray;
                            padding: 0;
                            border-radius: 50px;
                            &:hover {
                                background: $primary;
                                border-color: $primary;
                                svg {
                                    color: $white;
                                }
                            }
                            svg {
                                width: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
    &.right-side-party {
        .slime-grp {
            padding: 0px;
            .card-body-blk {
                padding: 20px;
                .user-list {
                    border-radius: .25rem;
                    display: flex;
                    display: -webkit-flex;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    padding-left: 0;
                    margin-bottom: 0;
                    .user-list-item {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        align-items: center;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        padding: 5px;
                        display: flex;
                        display: -webkit-flex;
                        border-radius: 6px;
                        background-color: #F6F7F8;
                        margin-bottom: 10px;
                        border: 0;
                        &:hover {
                            background-color: #F6F7F8;
                            transition: ease all 0.5s;
                            -webkit-transition: ease all 0.5s;
                            -ms-transition: ease all 0.5s;
                        }
                        .avatar {
                            margin-right: 0.8rem;
                            display: inline-block;
                            margin-bottom: 0;
                            height: 40px;
                            width: 40px;
                            position: relative;
                            flex-shrink: 0;
                            &::before {
                                top: 0;
                                bottom: inherit;
                            }
                            img {
                                border-radius: 0px;
                            }
                        }
                        .users-list-body {
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            align-items: center;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            justify-content: space-between;
                            -webkit-justify-content: space-between;
                            -ms-flex-pack: space-between;
                            flex-grow: 1;
                            .name-list-user {
                                display: grid;
                                display: -webkit-box;
                                display: -ms-flexbox;
                                display: flex;
                                align-items: center;
                                -webkit-box-align: center;
                                -ms-flex-align: center;
                                h5 {
                                    font-size: $font-size-15;
                                    font-weight: $font-weight-normal;
                                    margin-bottom: 2px;
                                    color: $text-color;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                                p {
                                    font-size: $font-size-12;
                                    color: $danger;
                                    display: -webkit-box;
                                    display: -ms-flexbox;
                                    display: flex;
                                    align-items: center;
                                    -webkit-box-align: center;
                                    -ms-flex-align: center;
                                    svg {
                                        width: 14px;
                                        height: 14px;
                                    }
                                }
                            }
                            .chat-user-time {
                                .chats-dot {
                                    a {
                                        color: $danger;
                                    }
                                }
                            }
                            .last-chat-time {
                                .text-muted {
                                    font-size: $font-size-10;
                                    color: $gray-900;
                                    font-weight: $font-weight-medium;
                                }
                                .new-message-count {
                                    width: 20px;
                                    height: 20px;
                                    display: flex;
                                    display: -webkit-flex;
                                    align-items: center;
                                    -webkit-align-items: center;
                                    justify-content: center;
                                    -webkit-justify-content: center;
                                    line-height: 0;
                                    font-size: 11px;
                                    background-color: $primary;
                                    color: $white;
                                    border-radius: 50%;
                                    margin-left: auto;
                                    margin-top: 5px;
                                }
                            }
                            .last-call-time {
                                display: -webkit-box;
                                display: -ms-flexbox;
                                display: flex;
                                align-items: center;
                                -webkit-box-align: center;
                                -ms-flex-align: center;
                                .call-recent {
                                    a {
                                        display: -webkit-box;
                                        display: -ms-flexbox;
                                        display: flex;
                                        align-items: center;
                                        -webkit-box-align: center;
                                        -ms-flex-align: center;
                                        justify-content: center;
                                        -webkit-justify-content: center;
                                        -ms-flex-pack: center;
                                        width: 30px;
                                        height: 30px;
                                        background: #B8BCC9;
                                        color: $gray-900;
                                        border-radius: 50px;
                                        svg {
                                            color: $gray-900;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .slimScrollDiv {
        height: calc(100vh - 110px) !important;
        .slimscroll {
            height: calc(100vh - 110px) !important;
        }
    }
    &.audio {
        .slimScrollDiv {
            height: calc(100vh - 305px) !important;
            .slimscroll {
                height: calc(100vh - 305px) !important;
            }
        }
    }
    .slime-grp {
        background: $white;
        padding: 15px;
        
		.chat-footer {
			position: sticky;
			left: 0px;
			right: 0px;
			bottom: 0px;
			padding: 15px;
			background: $white;
            border-radius: 10px;
			form {
				display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
				position: relative;
				.comman-icon {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					-webkit-transform: translateY(-50%);
					-ms-transform: translateY(-50%);
					a {
						color: $text-color;
					}
				}
				.smile-col {
					right: inherit;
					left: 15px;
				}
				.attach-col {
					right: inherit;
					left: 45px;
				}
				.micro-col {
					right: 50px;
					left: inherit;
					a {
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
						font-size: $font-size-16;
					}
				}
				.send-chat {
					right: 5px;
					a {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        align-items: center;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        justify-content: center;
                        -webkit-justify-content: center;
                        -ms-flex-pack: center;
						width: 34px;
						height: 34px;
						background: $primary;
						border-radius: 50px;
						&:hover {
							background: #1b2950;
							svg {
								color: $white;
							}
						}
						svg {
							width: 14px;
							color: $white;
						}
					}
				}
				.chat_form {
					border-radius: 20px;
					margin-right: 0px;
					background: $white;
					border: 1px solid rgba(145, 158, 171, 0.3);
					border-radius: 6px;
					height: 48px;
					padding: 10px 70px;
					&::placeholder {
						font-size: $font-size-base;
						color: rgba(0, 0, 0, 0.2);
					}
				}
			}
		}
    }
}
.owl-carousel.video-slide {
    .owl-nav.disabled {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        -ms-flex-pack: space-between;
        width: 100%;
    }
    .owl-nav {
        top: calc(50% - 24px);
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        position: absolute;
        @include respond-below(xxl) {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            justify-content: space-between;
            -webkit-justify-content: space-between;
            -ms-flex-pack: space-between;
        }
        button.owl-next, button.owl-prev {
            background-color: $light-500;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            justify-content: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            width: 20px;
            height: 20px;
            &:hover {
                background-color: $primary;
                i {
                    color: $white;
                }
            }
            i {
                color: $text-color;
            }
        }
    }
}
.audio-call-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    height: 100%;
    ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: space-evenly;
        -webkit-justify-content: space-evenly;
        -ms-flex-pack: space-evenly;
        width: 100%;
        li {
            position: relative;
            &.active {
                .avatar {
                    &::before {
                        content: "";
                        width: 220px;
                        height: 220px;
                        position: absolute;
                        border: 1px solid $primary;
                        border-radius: 50%;
                        left: -25px;
                        top: -25px;
                        @include respond-below(lg) {
                            width: 150px;
                            height: 150px;
                            left: -10px;
                            top: -10px;
                        }
                        @include respond-below(md) {
                            width: 100px;
                            height: 100px;
                            left: -5px;
                            top: -5px;
                        }
                    }
                }
            }
            .avatar {
                width: 170px;
                height: 170px;
                margin: 0;
                position: relative;
                @include respond-below(lg) {
                    width: 130px;
                    height: 130px;
                }
                @include respond-below(md) {
                    width: 90px;
                    height: 90px;
                }
                img {
                    width: 100%;
                    position: relative;
                }
            }
            .user-audio-call {
                h5 {
                    font-size: $font-size-14;
                    color: $white;
                    font-weight: $font-weight-normal;
                    text-transform: capitalize;
                    text-align: center;
                    margin-top: 35px;
                    @include respond-below(md) {
                        margin-top: 15px;
                    }
                }
            }
            .more-icon {
                position: absolute;
                bottom: 10px;
                right: inherit;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                z-index: 9;
                @include respond-below(md) {
                    bottom: 0px;
                }
                a {
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }
}
.audio-volume {
    position: absolute;
    width: fit-content;
    height: 134px;
    bottom: 20px;
    left: 20px;
    background: $gray-300;
    border-radius: 50px;
    padding: 10px 14px;
    width: 30px;
    padding-bottom: 30px;
    .volume-icons {
        a {
            position: absolute;
            bottom: 0;
            width: 30px;
            height: 30px;
            left: 0;
            border-radius: 50px;
            background: $white;
            border: 1px solid $warning;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            justify-content: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
        }
    }
}
.custom-input {
    width: 100%;
    height: 4px;
    width: 3px;
    height: 100%;
    background: $white;
    border-radius: 5px;
    writing-mode: vertical-lr;
}
.custom-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    background: $primary !important;
    border-radius: 50%;
    cursor: pointer;
}
.custom-input::-moz-range-thumb {
    width: 12px;
    height: 12px;
    background: $primary !important;
    border-radius: 50%;
    cursor: pointer;
}

.table-top-head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    &.low-stock-top-head {
        @include respond-below(sm) {
            flex-wrap: wrap;  
           justify-content: start;
        }
        li {
            margin-bottom: 10px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    li {
        margin-right: 10px;
        flex-shrink: 0;
        &:last-child {
            margin-right: 0;
        }
        #collapse-header {
            margin-right: 0;
            @include respond-below(lg) {
                display: none;
            }
        }
        a {
            height: 38px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            justify-content: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            border: 1px solid $gray-300;
            background: $white;
            border-radius: 8px;
            padding: 6px;
            svg {
                color: $gray-900;
            }
            &:hover {
                background: #e3e3e3;
            }
            label {
                margin-right: 10px;
            }
            &.btn-secondary {
                background-color: $gray-900;
                color: $white;
                padding: 10px;
                font-weight: $font-weight-bold;
                font-size: $font-size-base;
                &:hover {
                    background-color: $primary;
                }
                svg {
                    width: 16px;
                    margin-right: 5px;
                    color: $white;
                }
                @include respond-below(md) {
                    padding: 5px;
                    font-size: $font-size-base;
                }
            }
            .action-edit.sales-action {
                color: $blue;
            }
        }
        .status-toggle {
            height: 38px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            justify-content: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            border: 1px solid $gray-100;
            background: $white;
            border-radius: 8px;
            padding: 6px 10px;
            label {
                margin-right: 10px;
            }
        }
    }
}